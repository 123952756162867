import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL} from '../../environments/environment';
import {Navigation} from '../models/Navigation';

@Injectable({
  providedIn: 'root'
})
export class NavigationService {
  url = API_URL + 'authorization/navmenu/';
  constructor(private http: HttpClient) {
  }
 
  GetNavigation(role: number): Observable<Navigation[]> {
    return this.http.get<Navigation[]>(this.url+role);
  }
  
}
