import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL} from '../../environments/environment';
import {MasterGroup} from '../models/MasterGroup';

@Injectable({
  providedIn: 'root'
})
export class MasterGroupService {
  url_get = API_URL + 'mastergroup/getsuppliermastergroup/';
  url_create = API_URL + 'mastergroup/creategroup';
  url_delete = API_URL + 'mastergroup/deletemastergroup/';
  constructor(private http: HttpClient) {
  }
 
  GetMasterGroups(userId:number): Observable<MasterGroup[]> {
    return this.http.get<MasterGroup[]>(this.url_get+userId);
  }
  CreateMasterGroup(model:MasterGroup): Observable<boolean> {
    return this.http.post<boolean>(this.url_create,model);
  }
  DeleteMasterGroup(id:number): Observable<boolean> {
    return this.http.get<boolean>(this.url_delete+id);
  }
}
