import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'supplier-layout',
  templateUrl: './supplier-layout.component.html',
  styleUrls: ['./supplier-layout.component.css']
})
export class SupplierLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
   
  }
 
}


