import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL} from '../../environments/environment';
import {Login} from '../models/Login';
import {AppUser} from '../models/AppUser';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  url = API_URL + 'authorization/login';
  constructor(private http: HttpClient) {
  }
 
  Login(data: Login): Observable<AppUser> {
    return this.http.post<AppUser>(this.url, data);
  }

}
