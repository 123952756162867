import { Component,OnInit } from '@angular/core';
import {NavigationService} from '../../_services/nav.service';
import {Navigation} from '../../models/Navigation';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  navigation:Navigation[];
  constructor(private nav:NavigationService,private cookie:CookieService)
  {
  //  this.loadNavigation();
  }
  ngOnInit()
  {
   this.loadNavigation();
  }
  loadNavigation():void
  {

    if(this.cookie.check("barcodeuser"))
    {
     let  user=JSON.parse(this.cookie.get("barcodeuser"));
     if(user!=null)
     {
       this.nav.GetNavigation(user["role"]).subscribe(result=>{
         this.navigation=result;
       });
     }
    }
  }
}
