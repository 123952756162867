import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { QRCodeModule } from 'angularx-qrcode';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NgxBarcodeModule } from 'ngx-barcode';
/// Print ///
import {ParcelPrintComponent} from './print/parcel-print/parcel-print.component';
import {StickerPrintComponent} from './print/sticker-print/sticker-print.component';
import {SingleStickerComponent} from './print/single-sticker/single-sticker.component';
import {NotificationSheetComponent} from './notification-sheet/notification-sheet.component';

import {MatIconModule,MatMenuModule,MatSidenavModule,MatDialogModule,MatDividerModule,
  MatBadgeModule, MatCardModule, MatButtonModule,MatTabsModule,MatBottomSheetModule
  ,MatListModule} from '@angular/material';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,MatMenuModule,MatSidenavModule,
    MatDialogModule,MatDividerModule,MatBadgeModule,
    MatCardModule,MatButtonModule,QRCodeModule,NgxBarcodeModule,
    MatTabsModule,MatBottomSheetModule,MatListModule
  ],
  declarations: [
    HeaderComponent,
    SidebarComponent,
    ParcelPrintComponent,
    StickerPrintComponent,
    NotificationSheetComponent,
    SingleStickerComponent
  ],
  exports: [
    HeaderComponent,
    SidebarComponent,
    ParcelPrintComponent,
    StickerPrintComponent,
    NotificationSheetComponent,
    SingleStickerComponent
  ],
  entryComponents: [
    NotificationSheetComponent
  ]
})
export class ComponentsModule { }
