import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {StickerView} from '../../../views/StickerView';

import { PrintService } from '../../../_services/print.service';

@Component({
  selector: 'app-single-sticker-print',
  templateUrl: './single-sticker.component.html',
  styleUrls: ['./single-sticker.component.css']
})
export class SingleStickerComponent {
  model:StickerView;
  queryStringId:string;
  queryStringchallan:string;
  format:string="CODE39";
  constructor(private printService:PrintService, private router: Router,
    private route: ActivatedRoute)
  {
    this.queryStringId = this.route.snapshot.paramMap.get("id");
    this.queryStringchallan = this.route.snapshot.paramMap.get("challan");
    this.loadProduct();
  }
   public loadProduct():void
   {
     //this.printService.GetProductSticker(+this.queryStringId).subscribe(result=>{
      this.printService.GetProductStickerchallan(+this.queryStringId,this.queryStringchallan).subscribe(result=>{
         this.model=result;
         
     })
   }
   

  printPage(id:string) {
    let printContents, popupWin;
    printContents = document.getElementById(id).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          //........Customized style.......
          </style>
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
    //this.onCancel();
  }
  public onCancel():void
  {
      window.close();
  }
}
