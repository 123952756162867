import {Injectable} from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import {Observable} from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import {AppUser} from '../models/AppUser';
@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router, private cookieService: CookieService) {
  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    try {
      let user:AppUser = JSON.parse(this.cookieService.get('barcodeuser'));
      // console.log('token', user);
      if (user.token !== undefined && user.token !== null) {
        return true;
      }
      this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
      return false;

    } catch (e) {
      this.router.navigate(['/login'], {queryParams: {returnUrl: state.url}});
      return false;
    }

  }
}
