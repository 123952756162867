import { Component } from '@angular/core';
import {StickerView} from '../../../views/StickerView';
import {ProductService} from '../../../_services/product.service';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-sticker-print',
  templateUrl: './sticker-print.component.html',
  styleUrls: ['./sticker-print.component.css']
})
export class StickerPrintComponent {
  public myAngularxQrCode: string = null;
  product:StickerView[];
  queryStringId:string;
  queryStringchallan:string;
  array:number[]=[11,12,12,13,14,15,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1];
  constructor(private productService:ProductService,private router: Router,private route: ActivatedRoute)
  {
    this.queryStringId = this.route.snapshot.paramMap.get("id");
    this.queryStringchallan = this.route.snapshot.paramMap.get("challan");
    this.myAngularxQrCode = 'BC Print';
    this.loadProducts();
  }
  printPage(id:string) {
    let printContents, popupWin;
    printContents = document.getElementById(id).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          .innerbox
          {
             width:250px; /* or whatever width you want. */
             max-width:250px; /* or whatever width you want. */
             display: inline-block;
             height:100px;
             margin-left: 10px;
             margin-top:5px;
             border: 1px;
             border-style: solid;
             background-color: white;
          }
          </style>
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
        </head>
    <body onload="window.print()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
  public loadProducts():void
  {
    console.log(this.queryStringId);
    this.productService.GetProductByParcel(+this.queryStringId).subscribe(result=>{
      this.product=result;
      console.log(this.product);
    });
  }
  public onCancel():void
  {
    this.router.navigate(['/vouchers']);
  }
}
