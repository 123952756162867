import { Component } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import {ParcelView} from '../../../views/ParcelView';
import {SaleVoucherView} from '../../../views/SaleVoucherView';
import {ParcelService} from '../../../_services/parcel.service';
import { PrintService } from '../../../_services/print.service';
import { ProductService } from '../../../_services/product.service';
import { StickerView } from '../../../views/StickerView';

@Component({
  selector: 'app-parcel-print',
  templateUrl: './parcel-print.component.html',
  styleUrls: ['./parcel-print.component.css']
})
export class ParcelPrintComponent {
  parcelView:ParcelView;
  saleVoucher:SaleVoucherView;
  product:StickerView[];
  queryStringId:string;
  constructor(private parcelService:ParcelService,private printService:PrintService,
    private productService:ProductService,private router: Router,
    private route: ActivatedRoute)
  {
    this.queryStringId = this.route.snapshot.paramMap.get("id");

    this.loadOutParcelSticker(+this.queryStringId);
    this.loadSaleVoucher(+this.queryStringId);
    this.loadProducts();
  }
  public loadProducts():void
  {
   
    this.productService.GetProductByParcel(+this.queryStringId).subscribe(result=>{
      this.product=result;
    
    });
  }
  public loadOutParcelSticker(parcelId:number)
  {
       this.parcelService.GetOutParcelSticker(parcelId).subscribe(result=>{
        this.parcelView=result;
      
       });
  }
  public loadSaleVoucher(parcelId:number)
  {
       this.printService.GetSaleVoucherSupplierPrint(parcelId).subscribe(result=>{
        this.saleVoucher=result;
        console.log(this.saleVoucher);
       });
  }

  printPage(id:string) {
    let printContents, popupWin;
    printContents = document.getElementById(id).innerHTML;
    popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
    popupWin.document.open();
    popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
          //........Customized style.......
          </style>
          <link rel="stylesheet" href="https://maxcdn.bootstrapcdn.com/bootstrap/3.4.0/css/bootstrap.min.css">
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
    );
    popupWin.document.close();
  }
public getTotalPcs():number
{
  return this.saleVoucher.products.reduce((sum,current)=>sum+current.quantity,0);
}
public sumPurchase():number
  {
    return this.saleVoucher.products.reduce((sum,current)=>sum+current.purchasePrice,0);
  }
  public sumSalePrice():number
  {
    return this.saleVoucher.products.reduce((sum,current)=>sum+current.salePrice,0);
  }
  public onCancel():void
  {
    this.router.navigate(['/vouchers']);
  }
}
