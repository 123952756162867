import { Component } from '@angular/core';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';
@Component({
  selector: 'app-notification-sheet',
  templateUrl: './notification-sheet.component.html',
  styleUrls: ['./notification-sheet.component.css']
})
export class NotificationSheetComponent {
  title = 'barcode-baazar-app';
  constructor(private _bottomSheetRef: MatBottomSheetRef<NotificationSheetComponent>)
  {

  }

}
