
import { Component, OnInit,Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA} from '@angular/material/dialog';
import {Confirmation} from '../../models/Confirmation';
@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {
   conf:Confirmation;
  constructor(public dialogRef: MatDialogRef<ConfirmationComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Confirmation) { 
        this.conf=data;
    }

  ngOnInit() {
  }
  public onOK():void
  {
    this.dialogRef.close(true);
  }
  public  onCancel():void
  {
    this.dialogRef.close(false);
  }
}
