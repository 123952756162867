import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL} from '../../environments/environment';
import {Product} from '../models/Product';
import {StickerView} from '../views/StickerView';
import {TableResult} from '../views/TableResult';
@Injectable({
  providedIn: 'root'
})
export class ProductService {
  url = API_URL + 'product/products/';
  url_product_supplier = API_URL + 'product/productwithsupplier/';
  url_sticker_parcel=API_URL+'product/getproductbyparcel/';
  url_product=API_URL+'product/getproduct/';
  url_delete_product=API_URL+'product/deleteproduct/';
  url_changeproductdelete_product=API_URL+'product/changeproduct/';
  url_createdelete_product=API_URL+'product/createproduct/';
  url_update_product=API_URL+'product/updateproduct/';

  constructor(private http: HttpClient) {
  }
 
  GetProducts(pageSize:number,pageindex:number,filters:string[]): Observable<TableResult<Product>> {
    return this.http.post<TableResult<Product>>(this.url+pageSize+'/pagesize/'+pageindex+'/pageindex/table',filters);
  }
  GetProductWithSupplier(pageSize:number,pageindex:number,filters:string[]): Observable<TableResult<Product>> {
    return this.http.post<TableResult<Product>>(this.url_product_supplier+pageSize+'/pagesize/'+pageindex+'/pageindex/table',filters);
  }
  GetProductByParcel(parcel:number): Observable<StickerView[]> {
    return this.http.get<StickerView[]>(this.url_sticker_parcel+parcel);
  }
  GetProduct(id:number): Observable<Product> {
    return this.http.get<Product>(this.url_product+id);
  }
  DeleteProduct(id:number): Observable<boolean> {
    return this.http.delete<boolean>(this.url_delete_product+id);
  }
  ChangeProductStatus(id:number): Observable<boolean> {
    return this.http.get<boolean>(this.url_changeproductdelete_product+id);
  }
  CreateProduct(model:Product):Observable<boolean>{
    return this.http.post<boolean>(this.url_createdelete_product,model);
  }
  UpdateProduct(model:Product):Observable<boolean>{
    return this.http.post<boolean>(this.url_update_product,model)
  }
}
