// public class Products
// {
//     public int Id { get; set; }
//     public string Name { get; set; }
//     public string Alias { get; set; }
//     public string PrintName { get; set; }
//     public string GroupName { get; set; }
//     public int Unit { get; set; }
//     public string TaxCategory { get; set; }
//     public string HSNCode { get; set; }
//     public decimal PurchasePrice { get; set; }
//     public bool IsActive { get; set; }
//     public DateTime ? DeletedAt { get; set; }
//     public DateTime ? CreatedAt { get; set; }
// }

export class Product{
    constructor()
    {
        this.name=null;
        this.printName=null;
        this.name=null;
        this.hsnCode=null;
        
    }
    id:number;
    name:string;
    alias:string;
    printName:string;
    groupName:string;
    unit:number;
    taxCategory:string;
    hsnCode:string;
    salePrice:number;
    purchasePrice:number;
    isActive:boolean;
    deletedAt?:Date;
    createdAt?:Date;
    supplierName:string;
    supplierPrintName:string;
    GSTPER:number;
    PROFIT:Number;
}