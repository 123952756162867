import { NgModule } from '@angular/core';
import {MatIconModule,MatFormFieldModule,MatCheckboxModule,MatCardModule,MatButtonModule,
    MatInputModule,MatTooltipModule,MatSidenavModule,MatMenuModule,MatDividerModule,
    MatTableModule,MatPaginatorModule,MatDatepickerModule,MatSelectModule,MatProgressBarModule} from '@angular/material';
@NgModule({
  imports: [MatIconModule,MatFormFieldModule,MatCheckboxModule,
    MatCardModule,MatInputModule,MatTooltipModule,MatSidenavModule,MatDatepickerModule,
    MatMenuModule,MatDividerModule,MatButtonModule,MatTableModule,MatPaginatorModule,
    MatSelectModule,MatProgressBarModule],
  exports: [MatIconModule,MatFormFieldModule,MatCheckboxModule,
    MatCardModule,MatInputModule,MatTooltipModule,MatSidenavModule,MatDatepickerModule,
    MatMenuModule,MatDividerModule,MatButtonModule,MatTableModule,MatPaginatorModule,
    MatSelectModule,MatProgressBarModule ]
})
export class MaterialModule { }
