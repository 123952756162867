import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from './login/login.component';
import {SuperLayoutComponent} from './layouts/super-layout/super-layout.component';
import {SupplierLayoutComponent} from './layouts/supplier-layout/supplier-layout.component';
import {AuthGuard} from './security/auth.guard';
import { NotFoundComponent } from './_shared/notfound/notfound.component';
const routes: Routes =[
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path:'login',
    component:LoginComponent
  },
  {
    path: '',canActivate: [AuthGuard],
    component: SuperLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: './layouts/super-layout/super-layout.module#SuperLayoutModule'
  }]},
  {
    path: '',canActivate: [AuthGuard],
    component: SupplierLayoutComponent,
    children: [
        {
      path: '',
      loadChildren: './layouts/supplier-layout/supplier-layout.module#SupplierLayoutModule'
  }]},
  {path: 'notfound', component: NotFoundComponent},
  {path: '**', redirectTo: '/notfound'} 
];
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
