import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL} from '../../environments/environment';
import {ParcelView} from '../views/ParcelView';
import {Parcel} from '../models/Parcel';
import { TableResult } from '../views/TableResult';
@Injectable({
  providedIn: 'root'
})
export class ParcelService {
  url = API_URL + 'parcel/generateparcel';
  urlSupplierParcel = API_URL + 'parcel/supplierparcel';
  url_parcel=API_URL+'parcel/get/';
  url_out_parcel=API_URL+'parcel/outparcelsticker/';
  url_scan_parcel=API_URL+'parcel/scandeliverparcel/';
  url_change_parcel_status=API_URL+'parcel/changeparcelstatus/';
  url_delete_parceldetail=API_URL+'parcel/deleteparceldetail/';
  url_update_parcel=API_URL+'parcel/updateparcel';
  url_parcel_table=API_URL+'parcel/supplierparcel/';
  url_super_parcel_table=API_URL+'parcel/superparcel/';
  constructor(private http: HttpClient) {
  }
 
  GenerateParcel(model:ParcelView): Observable<boolean> {
    return this.http.post<boolean>(this.url,model);
  }
  UpdateParcel(model:ParcelView): Observable<boolean> {
    return this.http.put<boolean>(this.url_update_parcel,model);
  }
  GetSupplierParcel(): Observable<Parcel[]> {
    return this.http.get<Parcel[]>(this.urlSupplierParcel);
  }
  GetParcel(parcelId:number): Observable<ParcelView> {
    return this.http.get<ParcelView>(this.url_parcel+parcelId);
  }
  GetOutParcelSticker(parcelId:number): Observable<ParcelView> {
    return this.http.get<ParcelView>(this.url_out_parcel+parcelId);
  }
  GetScanDeliverParcel(parcelId:number): Observable<Parcel> {
    return this.http.get<Parcel>(this.url_scan_parcel+parcelId);
  }
  ChangeParcelStatus(status:number,list:Parcel[]): Observable<Parcel> {
    return this.http.post<Parcel>(this.url_change_parcel_status+status,list);
  }
  DeleteParcelDetail(id:number): Observable<Parcel> {
    return this.http.delete<Parcel>(this.url_delete_parceldetail+id);
  }

  ///Table //
  GetParcelSupplierTableResult(pageSize:number,pageindex:number,filters:string[]): Observable<TableResult<ParcelView>> {
    return this.http.post<TableResult<ParcelView>>(this.url_parcel_table+pageSize+'/pagesize/'+pageindex+'/pageindex/table',filters);
  }
  GetParcelSuperTableResult(pageSize:number,pageindex:number,filters:string[]): Observable<TableResult<ParcelView>> {
    return this.http.post<TableResult<ParcelView>>(this.url_super_parcel_table+pageSize+'/pagesize/'+pageindex+'/pageindex/table',filters);
  }
}
