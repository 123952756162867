import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from "@angular/common/http";
import {JwtInterceptor} from './_helpers/jwt.interceptor';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MaterialModule} from './app-material.module';
import { CookieService } from 'ngx-cookie-service';
import {ComponentsModule} from './components/components.module';
import {HttpClient,HTTP_INTERCEPTORS} from '@angular/common/http';
/// Module //
import {SuperLayoutComponent} from './layouts/super-layout/super-layout.component';
import {SupplierLayoutComponent} from './layouts/supplier-layout/supplier-layout.component';
import {ConfirmationComponent} from './_shared/confirmation/confirmation.component';
import { NotFoundComponent } from './_shared/notfound/notfound.component';
import { CreateProductConfirmationComponent } from './_shared/createproduct/createproduct-confirm.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    SuperLayoutComponent,SupplierLayoutComponent,ConfirmationComponent,
    NotFoundComponent,CreateProductConfirmationComponent
  ],
  imports: [
    BrowserModule,FormsModule,HttpClientModule,ReactiveFormsModule,
    AppRoutingModule,BrowserAnimationsModule,MaterialModule,ComponentsModule
  ],
  entryComponents:[
    ConfirmationComponent,CreateProductConfirmationComponent
  ],
  providers: [HttpClient,CookieService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule { }
