import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';
import {API_URL} from '../../environments/environment';
import {SaleVoucherView} from '../views/SaleVoucherView';
import { StickerView } from '../views/StickerView';
@Injectable({
  providedIn: 'root'
})
export class PrintService {
  url = API_URL + 'print/suppliersalevoucher/';
  url_product = API_URL + 'print/productsticker/';
  url_product_challan = API_URL + 'print/productstickerbychallan/';
  constructor(private http: HttpClient) {
  }
 
  GetSaleVoucherSupplierPrint(parcelId:number): Observable<SaleVoucherView> {
    return this.http.get<SaleVoucherView>(this.url+parcelId);
  }
  
  GetProductSticker(product:number): Observable<StickerView> {
    return this.http.get<StickerView>(this.url_product+product);
  }
  GetProductStickerchallan(product:number,challanno:string): Observable<StickerView> {
    return this.http.get<StickerView>(this.url_product_challan+product+'/productid/'+challanno+'/challan/');
  }
  
}
