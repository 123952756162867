import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {AuthenticationService} from '../_services/auth.service';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
/// Service ///
import { CookieService } from 'ngx-cookie-service';
/// Model ///
import {Login} from '../models/Login';
import {AppUser} from '../models/AppUser';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  isProgres:boolean=false;
  hide:boolean=false;
  isLoader:boolean=false;
  appUser:AppUser;
  formcontrols: FormGroup;
  errors: string=null;
  constructor(  private router: Router,private fB: FormBuilder,
    private route: ActivatedRoute,private auth:AuthenticationService,private cookie:CookieService) { }

  ngOnInit() {
    this.formcontrols = this.fB.group({
      userName: ['', [Validators.required]],
      password: ['', [Validators.required]],
      });
  }
  get form() {
    return this.formcontrols.value;
  }
  public onLogin():void
  {
    this.errors=null;
    if(this.form.userName=="")
    {
      this.errors="User name is required";
      return;
    }
    else if(this.form.password=="")
    {
      this.errors="Password is required";
      return;
    }
   if(this.formcontrols.valid)
   {
     this.isLoader=true;
    let login=new Login();
    login.userName=this.form.userName;
    login.password=this.form.password;
    this.auth.Login(login).subscribe(result=>{

      if(result["message"]!=undefined)
      {
       this.errors=result["message"];
       this.isLoader=false;
      }
      else
      {
        this.isProgres=true;
        this.cookie.set("barcodeuser",JSON.stringify(result));
        if(result.role==3)
        this.router.navigate(['/notice-board']);
        else
        this.router.navigate(['/dashboard']);
        this.isLoader=false;
      }
      

    }, error => {
      this.isLoader=false;
      this.isLoader=false;
       console.log(error.message);
    });
 
   }
  }
 
}
