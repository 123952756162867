import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MatBottomSheet, MatBottomSheetRef} from '@angular/material/bottom-sheet';

/// Service ///
import { CookieService } from 'ngx-cookie-service';
import { NotificationSheetComponent } from '../notification-sheet/notification-sheet.component';

/// Model ///
import {AppUser} from '../../models/AppUser';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  appUser:AppUser;
  constructor(private cookie:CookieService, private router: Router,
    private _bottomSheet: MatBottomSheet)
  {
    this.appUser = JSON.parse(this.cookie.get('barcodeuser'));
  }
  openBottomSheet(): void {
    this._bottomSheet.open(NotificationSheetComponent);
  }
  logOut():void
  {
    this.cookie.delete("barcodeuser");
    this.router.navigate(['/login']);
  }
}
